exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-attila-chapter-one-tsx": () => import("./../../../src/pages/attila/chapter-one.tsx" /* webpackChunkName: "component---src-pages-attila-chapter-one-tsx" */),
  "component---src-pages-attila-chapter-two-tsx": () => import("./../../../src/pages/attila/chapter-two.tsx" /* webpackChunkName: "component---src-pages-attila-chapter-two-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-eradication-of-smallpox-chapter-one-tsx": () => import("./../../../src/pages/eradication-of-smallpox/chapter-one.tsx" /* webpackChunkName: "component---src-pages-eradication-of-smallpox-chapter-one-tsx" */),
  "component---src-pages-eradication-of-smallpox-chapter-two-tsx": () => import("./../../../src/pages/eradication-of-smallpox/chapter-two.tsx" /* webpackChunkName: "component---src-pages-eradication-of-smallpox-chapter-two-tsx" */),
  "component---src-pages-eradication-of-smallpox-foreword-tsx": () => import("./../../../src/pages/eradication-of-smallpox/foreword.tsx" /* webpackChunkName: "component---src-pages-eradication-of-smallpox-foreword-tsx" */),
  "component---src-pages-fetish-chapter-one-tsx": () => import("./../../../src/pages/fetish/chapter-one.tsx" /* webpackChunkName: "component---src-pages-fetish-chapter-one-tsx" */),
  "component---src-pages-fetish-chapter-two-tsx": () => import("./../../../src/pages/fetish/chapter-two.tsx" /* webpackChunkName: "component---src-pages-fetish-chapter-two-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meat-of-the-horse-chapter-one-tsx": () => import("./../../../src/pages/meat-of-the-horse/chapter-one.tsx" /* webpackChunkName: "component---src-pages-meat-of-the-horse-chapter-one-tsx" */),
  "component---src-pages-meat-of-the-horse-chapter-two-tsx": () => import("./../../../src/pages/meat-of-the-horse/chapter-two.tsx" /* webpackChunkName: "component---src-pages-meat-of-the-horse-chapter-two-tsx" */),
  "component---src-pages-what-i-care-about-tsx": () => import("./../../../src/pages/what-i-care-about.tsx" /* webpackChunkName: "component---src-pages-what-i-care-about-tsx" */),
  "component---src-pages-what-i-want-from-readers-tsx": () => import("./../../../src/pages/what-i-want-from-readers.tsx" /* webpackChunkName: "component---src-pages-what-i-want-from-readers-tsx" */)
}

